export const initExerciceDownload = () => {

	const stripHtml = (text:string):string => {
		if (!text) { return "";}
		return text.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, " ").replace(/\s+/, ' ').replace("&nbsp;", " ");
	};

	const stripWhiteSpace = (text:string) => {
		if (!text) { return "";}
		for (let i = 0; i < 100; i++) {
			text = text.replace("\t", " ");
			text = text.replace("  ", " ");
		}
		return text;
	};

	const createDownload = (filename:string, text:string) => {
		const download = document.createElement("a") as HTMLAnchorElement;
		download.href = `data:text/plain;charset=utf-8, ${encodeURIComponent(text)}`;
		download.download = filename;
		download.style.display = "none";
		document.body.appendChild(download);
		download.click();
		document.body.removeChild(download);
	};

	const downloadExercise = (exercise:HTMLElement) => {
		const content = exercise.querySelector<HTMLElement>("exercise-content");
		if (!content) { return;}
		const answer = content.querySelector<HTMLElement>(".ck-content.ck-editor__editable > *");
		if (!answer) { return;}
		const clone = content.cloneNode(true) as HTMLElement;
		clone.querySelector<HTMLElement>("fancy-exercise-editor")?.remove();
		clone.querySelector<HTMLElement>(".ck.ck-editor")?.remove();
		clone.append(answer);
		const text = stripHtml(clone.innerHTML);
		const textStripped = stripWhiteSpace(text);
		createDownload("Aufgabe.txt", textStripped.trim());
	};

	const createDownloadButton = (exercise:HTMLElement) => {
		if (!exercise) { return;}
		const innerContent = exercise.querySelector<HTMLElement>("inner-content");
		const hasEditor = Boolean(exercise.querySelector<HTMLElement>("fancy-exercise-editor"));
		if (!innerContent || !hasEditor) { return;}

		const btn = document.createElement("button") as HTMLButtonElement;
		btn.classList.add("exercise-download");
		btn.innerText = "als Text herunterladen";
		innerContent.append(btn);
		btn.addEventListener("click", ()=>{
			downloadExercise(exercise);
		});
	};

	const allExercises = Array.from(document.querySelectorAll<HTMLElement>(`section[content-type="exercise"]`));
	if (!allExercises) { return;}
	for (const ex of allExercises) {
		createDownloadButton(ex);
	}
};
setTimeout(initExerciceDownload, 0);
